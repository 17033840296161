<template>
  <div class="m-auto flex max-w-[544px] flex-col items-center gap-32">
    <ImageRenderer v-if="image" v-bind="image" class="w-full max-w-[260px]" />

    <div class="flex flex-col items-center gap-4 text-center">
      <div class="heading-1">{{ title }}</div>
      <div class="body-1">{{ text }}</div>
    </div>
    <RevForm
      class="w-full"
      :initial-values="initialValues"
      :validate="validate()"
      @submit="onSubmit"
    >
      <template #default="{ values, errors }">
        <div class="flex flex-col gap-32">
          <div class="flex flex-col gap-16 md:flex-row md:items-start">
            <RevInputText
              id="email-collector"
              v-model="values.email"
              class="grow"
              :error="errors.email"
              :label="i18n(translations.inputLabel)"
              type="email"
            />

            <CmsButtonBase
              :disabled="submitButtonIsDisabled"
              :loading="isLoading"
              type="submit"
              variant="primary"
              width="adaptive"
            >
              {{ buttonLabel }}
            </CmsButtonBase>
          </div>

          <div>
            <RevCheckbox
              v-if="!!checkboxLabel"
              :id="`allowEmailCollection_${id}`"
              v-model="values.allowEmailCollection"
              :error="errors.allowEmailCollection"
            >
              <template #label>
                {{ checkboxLabel }}

                <RevLink v-if="pdf" target="_blank" :to="pdf.url">
                  <span class="body-1-bold"> {{ pdf.label }} </span>
                </RevLink>
              </template>
            </RevCheckbox>

            <p
              v-if="errors.allowEmailCollection"
              class="text-action-danger-hi body-2 mt-4"
            >
              {{ errors.allowEmailCollection }}
            </p>
          </div>
        </div>
      </template>
    </RevForm>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { type EmailCollectorApiProps } from '@backmarket/http-api/src/api-specs-content/models/email-collector'
import CmsButtonBase from '@backmarket/nuxt-layer-cms/CmsButtonBase.vue'
import type { ContentBlockProps } from '@backmarket/nuxt-layer-cms/models/content-block'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCheckbox } from '@ds/components/Checkbox'
import {
  RevForm,
  booleanValue,
  makeValidate,
  required,
} from '@ds/components/Form'
import { RevInputText } from '@ds/components/InputText'
import { RevLink } from '@ds/components/Link'

import ImageRenderer from '../../shared-components/ImageRenderer/ImageRenderer.vue'

import translations from './EmailCollector.translations'
import { useCollectEmail } from './useCollectEmail'

export type EmailCollectorProps = ContentBlockProps & EmailCollectorApiProps

const props = defineProps<EmailCollectorProps>()

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const initialValues = {
  email: '',
  allowEmailCollection: false,
}

const i18n = useI18n()

const { collectEmail, isLoading, submitButtonIsDisabled } = useCollectEmail()

function validate() {
  const mandatoryError = i18n(translations.formMandatoryError)
  const requiredField = required(mandatoryError)

  if (!props.checkboxLabel)
    return makeValidate<typeof initialValues>({
      email: [requiredField],
    })

  const isTrue = booleanValue(true, i18n(translations.checkboxMandatoryError))

  return makeValidate<typeof initialValues>({
    email: requiredField,
    allowEmailCollection: isTrue,
  })
}

const trackingData = computed(() => ({
  ...(props.tracking || {}),
  name: props.title,
}))
function onSubmit(values: typeof initialValues) {
  collectEmail({
    email: <string>values.email,
    trackingData: trackingData.value,
  })
}
</script>
