export default {
  inputLabel: {
    id: 'email_collector_input_label',
    defaultMessage: 'E-mail',
  },
  toastSuccessTitle: {
    id: 'email_collector_toast_success_title',
    defaultMessage: 'Perfect!',
  },
  toastSuccessMessage: {
    id: 'email_collector_toast_success_message',
    defaultMessage: 'Thanks for subscribing to our newsletter',
  },
  formMandatoryError: {
    id: 'email_collector_mandatory_field_error',
    defaultMessage: 'This field is mandatory',
  },
  checkboxMandatoryError: {
    id: 'email_collector_mandatory_checkbox_error',
    defaultMessage: 'You must accept',
  },
}
